.wd3Temp {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding-left: 10px;
}
.wd3Temp > span {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  align-items: flex-end;
  width: 35px;
  margin-right: 10px;
}

.wd3Title {
  font-size: 14px;
  margin-left: 11px;
}

.wd3 {
  /* background-color: rgba(0, 0, 0, 0.034); */
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
}

.wd3layer {
  width: 100%;
  height: 100%;
  position: absolute;

  top: 0;
  left: 0;
}
