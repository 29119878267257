.contactMain {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contactForm {
  width: 800px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}
.contactMessage {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.contactMessage textarea {
  max-width: 100%;
  border-radius: 5px;
  min-height: 150px;
  padding: 5px;
  background: var(--bg-overlay);
  resize: none;
  outline-offset: 2px;
  font-size: 16px;
  border: var(--glass-border-1);
}
.contactMessage textarea:focus {
  background: var(--font-color-1);
}
.contactForm label {
  color: var(--font-color);
  margin-bottom: 8px;
}
.contactName {
  display: flex;
  flex-direction: column;
  width: inherit;
  width: 50%;
}
.contactName input {
  height: 42px;
  border-radius: 5px;
  border: var(--glass-border-1);
  background: var(--bg-overlay);
  outline-offset: 2px;
  font-size: 16px;
}
.contactName input:focus {
  background: var(--font-color-1);
}
.contactNameCont {
  display: flex;
  gap: 20px;
  width: 100%;
}
.contactTitle {
  width: 100%;
  font-size: 46px;
  text-align: center;
  margin-bottom: 50px;
}
.contactSendBtn {
  width: 150px;
  height: 42px;
  border-radius: 5px;
  background: var(--link-color);
  border: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.contactSendBtn:hover {
  background: rgb(22, 143, 68);
}
@media (max-width: 920px) {
  .contactForm {
    width: 90%;
  }
}
@media (max-width: 500px) {
  .contactNameCont {
    flex-direction: column;
  }
  .contactName {
    width: 100%;
  }
  .contactTitle {
    font-size: 36px;
  }
  .contactMain {
    margin-top: 30px;
    height: 100%;
  }
}
