.profileContainer {
  position: fixed;
  top: 0;
  left: 0;

  background-color: var(--bg-overlay);
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: space-around;
  z-index: 999;
  transition: all 0.3s linear;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  height: 100%;
  transition: all 0.5s linear;
}

.profilePic {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.logoutBtn {
  border: none;

  background: transparent;
  color: var(--font-color-3);
  opacity: 0.8;
  cursor: pointer;
  font-size: 16px;
}

.logoutBtn:hover {
  opacity: 1;
}
.listsContainer {
  border-top: 1px solid var(--bg-overlay-1);
  width: 100%;

  overflow-y: scroll;
  position: relative;
}

.profileWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.editProfileBtn {
  color: var(--font-color-3);
  opacity: 0.8;
}
.editProfileBtn:hover {
  opacity: 1;
}
.profileBtnWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  align-items: center;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profileOpenCloseBtn {
  color: var(--font-color-3);
  height: 30px;
}

.profileBtnHome {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
  height: 40px;
  width: 40px;
}

.contactLinkContainer {
  display: flex;
  gap: 10px;
}
.contactLink {
  color: var(--font-color-3);
  font-size: 12px;
}

.contactLink:hover {
  color: var(--link-color) !important;
}
.loginAlert {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--glass-bg-2);
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99;
  gap: 20px;
}
.loginAlertCnl,
.loginAlertLogin {
  width: 100px;
  height: 35px;
  border: var(--glass-border-1);
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}
.alertBtnWrap {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}
.loginAlertLogin {
  background-color: rgb(39, 117, 235);
  color: white;
}
.alertMessageLogin {
  width: 215px;
  text-align: center;

  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.alertMessageWrap {
  background-color: var(--bg-overlay);
  width: 100%;
  height: 130px;
}
.profileTitle {
  color: var(--font-color);
  margin: 0;
  height: 90px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
