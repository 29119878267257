.blackScreen {
  width: 200px;
  height: 150px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.blcScrnBtn {
  background: transparent;
  height: 30px;
  color: rgb(207, 207, 207);
  opacity: 0.3;
  cursor: pointer;
  position: absolute;
  bottom: 65px;
  border: none;
}

.colorsWrap {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.colorsBtn {
  width: 100%;
  height: 20px;
  border: none;
  cursor: pointer;
}
.colorsBtn:hover {
  opacity: 1;
}
