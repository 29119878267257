.searchForm {
  height: 40px;

  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;
  width: 410px;
  background: transparent;
}
#locationInput {
  border: none !important;
  width: 400px;
  background-color: var(--bg-overlay);
  padding: 0;
  padding-left: 10px;
  font-size: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.searchGeo {
  position: absolute;
  z-index: 99;
  width: 410px;
  display: flex;
  justify-content: center;

  background: transparent;
  top: -30px;
}

.cancelSearch {
  position: absolute;
  z-index: 99;
  right: 6px;
  color: var(--bg-color-2);
  top: 10px;
  border: none;
  background: transparent;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.8;
}

.getLocationBtn {
  height: 40px;
  color: var(--bg-color-2);

  margin: 0;
  border: none;
  background: white;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: transparent;
  position: absolute;
  top: 0;
  right: 66px;
  opacity: 0.8;
}
.getLocationBtn:hover,
.cancelSearch:hover {
  opacity: 1;
  color: var(--link-color);
}
