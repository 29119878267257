.newsWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  border-color: var(--glass-border-1);
  overflow-y: auto;
}

.newsWrapper {
  width: 610px;
  height: 739px;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  border-radius: 10px;
  overflow: hidden;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color-4);
}

.newsWrapper > h1 {
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.newsCategory {
  width: inherit;
  display: flex;
  flex-flow: wrap;
  gap: 5px;
  margin-bottom: 10px;
  margin-right: 5px;
  margin-left: 5px;
  width: auto;
}

.newsScroll {
  overflow-y: auto;
  height: 624px;
  border-radius: 10px;
}

.catButton {
  border: none;
  background: var(--bg-overlay-1);
  width: fit-content;
  color: var(--font-color);
}

.catButton:hover {
  cursor: pointer;
  background-color: var(--background-color);
}
