/* Custom Dark Mode Toggle Element */
.toggle-theme-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 5px;
  right: 5px;
  opacity: 0.2;
}

.toggle-theme-wrapper:hover {
  opacity: 1;
}
