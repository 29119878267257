.authContainer {
  background-color: var(--bg-input);
  height: 580px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  border-radius: 5px;
}

.authMainCont {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.authTitle {
  font-size: 35px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.authForm {
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  justify-content: center;
  align-items: center;
}

.inputPassword,
.inputEmail {
  display: flex;
  flex-direction: column;
  width: 80%;
  row-gap: 5px;
  position: relative;
}

.signUpInput:focus {
  outline: none !important;
  border-bottom: 2px solid var(--link-color);
}
.goLogIn {
  color: var(--link-color);
}

.signUpInput {
  outline: 0;
  font-size: 16px;
  height: 45px;
  border: none;
  padding-left: 40px;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  background-color: var(--bg-input);
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--font-color);
}
.signUpInput::placeholder {
  color: var(--bg-color-2);
}

.signUpCreate {
  height: 45px;
  /* background-color: rgb(39, 117, 235); */
  background-color: var(--link-color);
  width: 80%;
  border-radius: 5px;
  border: none;
  color: white;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin-top: 5px;
}

.signUpInfo {
  font-size: 10px;
  color: var(--font-color-3);
  display: flex;
  justify-content: center;
}
.signUpInfo div {
  width: 80%;
}
a {
  /* color: rgb(39, 117, 235); */
}

.signUpIcons {
  position: absolute;
  bottom: 13px;
  left: 10px;
  font-size: 22px !important;

  color: rgb(158, 158, 158);
}

.logInInfo {
  width: 80%;
  color: var(--font-color-3);
}

.logInInfo a {
  font-weight: 500;
}

.authHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signUpCreate:disabled,
.signUpCreate[disabled]:hover {
  opacity: 0.5;
  cursor: auto;
}

.passInfo {
  position: absolute;
  width: 90%;
  background-color: var(--bg-overlay);
  color: var(--color-alert);
  top: 76px;
  z-index: 25;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  padding: 4px;
  left: 30px;
}
.triangle {
  position: absolute;
  top: -9px;
  left: 50px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  z-index: 99;
  border-bottom: 8px solid rgb(211, 211, 211);
}
.errorPass {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: space-between;
}

.errorIcon {
  font-size: 16px !important;
}

.rightIcon {
  color: var(--color-alert-1);
  font-size: 15px !important;
}
.alertFalse {
  color: var(--color-alert);
}
.alertTrue {
  color: var(--color-alert-1);
}

.visibility {
  position: absolute;
  bottom: 13px;
  right: 7px;
  font-size: 20px !important;
  cursor: pointer;
  color: #b1aeae;
}

@media (max-width: 768px) {
  .signUpContainer {
    height: 100%;
    width: 100%;
    justify-content: center;
    row-gap: 40px;
  }

  .signUpInput {
    font-size: 16px;
    height: 45px !important;
  }
}

.invalidCred {
  color: var(--color-alert);
}
