.updateMainCont {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.updateProfileContainer {
  width: 500px;
  background-color: var(--bg-overlay-1);
  border-radius: 10px;

  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.updateProfilePic {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
}

.updateProfileWrap {
  border-top: 1px solid grey;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 306px;
  justify-content: flex-start;
  padding-top: 20px;
  gap: 30px;
  position: relative;
}

.updateProfileInput:focus {
  outline: none !important;
  border-bottom: 2px solid var(--link-color);
}

.updateProfileInput {
  outline: 0;
  font-size: 16px;
  height: 45px;
  border: none;
  padding-left: 15px;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  background-color: var(--bg-overlay);
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--font-color);
  max-width: 100%;
  transition: all 0.3s linear;
  z-index: 99;
}

.inputWrapProfile {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  gap: 7.5px;
}

.cancelProfileBtn {
  height: 45px;
  background-color: var(--link-color);
  width: 80%;
  border-radius: 5px;
  border: none;
  color: white;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin-top: 5px;
  opacity: 0.9;
}

.updateProfileBtn:hover,
.cancelProfileBtn:hover {
  opacity: 1;
}
.passUpdateInfo {
  position: absolute;
  width: 80%;
  background-color: var(--bg-overlay);
  color: var(--color-alert);
  top: 56px;
  z-index: 25;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  padding: 4px;
  left: 69px;
}

.updateBtnWrap {
  display: flex;
  width: 80%;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.updateProfPic {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  transition: all 0.4s linear;
  height: 130px;
}
.updatePicIcon {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s linear;
  opacity: 0.3;
}
.photoAddIcon {
  color: rgba(0, 0, 0, 0.575);
}

.updatePicIcon:hover {
  background-color: rgba(0, 0, 0, 0.384);
  transition: all 0.3s linear;
  align-items: center;
  opacity: 1;
}

.custom-file-icon {
  display: none;
}

.profileUpdateError {
  color: var(--color-alert);
}
.profileSuccesMessage {
  color: var(--color-alert-1);
}
.updateProfileTitle {
  font-size: 36px;
}
.updateContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 36px;
  margin-top: 14px;
}
.saveBtnUpdate {
  height: 45px;
  background-color: var(--link-color);
  width: 60px;
  border-radius: 5px;
  border: none;
  color: white;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.9;
  position: absolute;
  right: 0;
  z-index: 0;
}

.updateLabel {
  width: 75px;
}

.passVisIcon {
  position: absolute;
  bottom: 13px;
  right: 73px;
  font-size: 20px !important;
  cursor: pointer;
  color: #b1aeae;
  z-index: 101;
}
.saveBtnUpdatePic {
  height: 36px;
  background-color: var(--link-color);

  border: none;
  color: white;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;

  position: absolute;

  bottom: -2px;
  width: 100%;
  z-index: 5;
}

.deleteProfileBtn {
  height: 35px;
  background-color: var(--color-alert);
  width: 50%;
  border-radius: 5px;
  border: none;
  color: white;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin-top: 5px;
  opacity: 0.9;
  position: absolute;
  bottom: 0px;
}

@media screen and (max-width: 499px) {
  .updateProfileContainer {
    width: 100%;
    border: none;
    background: transparent;
  }

  .updateProfileWrap {
    width: 100%;
    height: 100%;
    gap: 20px;
  }
  .inputWrapProfile {
    flex-direction: column;
    width: 317.5px;
    align-items: flex-start;
  }
  .updateProfileInput {
    width: 100%;
  }
  .updatePicIcon {
    width: 80px;
    height: 80px;
  }
  .updateProfilePic {
    width: 80px;
    height: 80px;
  }
  .updateProfPic {
    height: 80px;
  }
  .saveBtnUpdate {
    bottom: 0;
  }
  .passUpdateInfo {
    top: 80px;
    left: 0;
  }
  .profileSuccesMessage {
    width: 320px;
  }
  .profileUpdateError {
    width: 320px;
  }
  .updateMainCont {
    height: 100%;
  }
}
