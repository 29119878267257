.clockCont {
  width: 400px;
  height: 340px;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  border-radius: 10px;
  background: var(--bg-color-4);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.clockBtnWrap {
  position: absolute;
  bottom: 10px;
  display: flex;
  width: 300px;
  gap: 20px;
  justify-content: center;
}
.clockBtnAll {
  border: none;
  height: 22px;

  background: none;
  color: var(--font-color);
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3 linear;
  opacity: 0.5;
}
.clockBtnAllActive {
  /* border-bottom: 2px solid var(--font-color); */
  transition: all 0.3 linear;
  opacity: 1;
}
.clockBtnAll:hover {
  opacity: 1;
}
.clockBtnAll:active {
  opacity: 0.5;
}
