.stopWatchCont {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  transition: all 0.3s linear;
}
.stopWatchMain {
  font-size: 56px;
  transition: all 0.3s linear;
}

.stwBtnStart,
.stwBtnLap,
.stwBtnReset {
  height: 35px;
  width: 100px;
  color: white;
  border: var(--glass-border-1);
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
}
.stwBtnStart {
  background-color: var(--color-purple);
}
.stwBtnStart:hover {
  background-color: var(--color-alert-1);
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
}
.stwBtnLap {
  background-color: var(--bg-color-4);
  color: var(--font-color);
}
.stwBtnLap:disabled,
.stwBtnLap[disabled]:hover {
  opacity: 0.5;
  cursor: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.stwBtnReset {
  background-color: var(--bg-color-4);
  color: var(--font-color);
}
.stwBtnLap:hover {
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
}
.stwBtnReset:hover {
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
}
.stwBtn {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.stwLapListCont {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 135px;
  overflow-y: scroll;
  width: 80%;
  transition: all 0.3s linear;
}
.showLapList {
  height: 0;
}
.stwLapList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 96%;
}
.fastLap {
  color: var(--color-alert-1);
}
