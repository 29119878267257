.covidTooltip {
  display: flex;
  flex-direction: column;
  background-color: var(--font-color-2);
  border: 1px solid var(--font-color-3);
  padding: 5px;
}

.covidTooltip span {
  font-weight: 500;
}
.covidTooltip p {
  text-transform: capitalize;
  margin-top: 2px;
  margin-bottom: 0;
}

.totalChartData {
  padding-top: 35px;
}
