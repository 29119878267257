.listWrap {
  height: 45px;
  width: 100%;
  background-color: var(--bg-overlay-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: var(--font-color);
  position: relative;
}
.listWrap:hover {
  background: var(--link-color);
  color: var(--font-color-2);
}
.listWrap:active {
  background: var(--bg-overlay-1);
}

.switchList {
  opacity: 0.7;
  z-index: 99;
}

.switchList:hover {
  opacity: 1;
}

.widgetTitle {
  padding-left: 20px;
}

.listOnBtn {
  color: var(--color-alert-1);
  font-size: 16px;
  margin-right: 10px;
  text-shadow: 0 0 4px #0fa, 0 0 5px #0fa, 0 0 7px #0fa, 0 0 10px #0fa;
  opacity: 0.8;
}
.listOffBtn {
  color: var(--color-alert);
  font-size: 16px;
  margin-right: 10px;
  opacity: 0.7;
  text-shadow: 0 0 7px rgb(241, 56, 81), 0 0 10px rgb(241, 56, 81);
}

.iconWrapList {
  display: flex;
  gap: 5px;
  height: 100%;
  align-items: center;
}
