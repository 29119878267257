.App {
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; */
}

.toggle-container {
  position: absolute;
  top: 10px;
  right: 10px;
}
.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;
  height: 100vh;
}

.cursor {
  height: 20px;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0.5;
  z-index: 150;
}
.cursor:hover {
  cursor: grab;
  opacity: 1;
}

/* Utility Classes */

.widgetContainer {
  width: auto;
  height: auto;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  background: var(--bg-color-4);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
