.crypMainContainer {
  width: 400px;
  height: fit-content;
  background: var(--bg-color-4);
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  border-radius: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  position: relative;
}

#cryptoInput {
  border: none !important;
  width: 390px;
  background-color: var(--bg-overlay);
  padding: 0;
  padding-left: 10px;
  font-size: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: var(--font-color-3);
}

.searchInputForex {
}

.cryptoAddBtn {
  width: 45px;
  height: 40px;
  background: transparent;
  border: none;
  opacity: 0.7;
  position: absolute;
  right: 65px;
  cursor: pointer;
  background: var(--color-alert-1);
  color: white;
}
.cryptoCancelBtn {
  width: 60px;
  height: 40px;
  border: none;
  opacity: 0.7;
  position: absolute;
  right: 5px;
  background: transparent;
  cursor: pointer;
  background: var(--color-alert);
  color: white;
  border-radius: 0 10px 0 0;
}
.cryptoCancelBtn:hover {
  opacity: 1;
}
.cryptoAddBtn:hover {
  opacity: 1;
}

.cryptoAlert {
  position: absolute;
  height: 100px;
  width: 400px;
  border-radius: 5px;
  color: var(--color-alert);
  font-size: 18px;
  background: var(--bg-overlay);

  top: 50px;
  left: 5px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
}
.cryptoSearchBtn {
  border: none;
  background: transparent;
  width: 50px;
  color: var(--font-color-3);
  cursor: pointer;
  height: 23px;
  display: flex;
  justify-content: flex-start;
  opacity: 0.7;
}
.cryptoSearchBtn:hover {
  opacity: 1;
}

.cryptoAddBtnMain {
  height: 80px;
  width: 290px;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  background: var(--bg-overlay);
  cursor: pointer;
  color: var(--font-color-3);
  font-size: 16px;
}
.cryptoContainerBtn {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cryptoAddBtnMain:hover {
  background: var(--bg-overlay-1);
}
.cryptoAddBtnMain:active {
  background: var(--bg-overlay);
}
