.todoWrap {
  width: 700px;
  height: 500px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.todo-category {
  width: 35%;
  height: 100%;
}
.todo-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 5px;
  flex-direction: column;
}
.todo-input {
  height: 38px;
  width: 345px;
  border: none;
  border-radius: 5px 0 0 5px;
  background-color: var(--bg-gradient-1);
  color: var(--font-color);
  border: var(--glass-border-1);
  box-sizing: border-box;
}
.todo-tab-content {
  width: 65%;
  height: 100%;
  background-color: var(--bg-overlay-1);
}
.btn-add {
  height: 38px;
  width: 55px;
  border: none;
  background-color: var(--bg-gradient-1);
  color: var(--font-color);
  border: var(--glass-border-1);
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  margin-left: 5px;
}
.btn-add:hover {
  background-color: var(--bg-overlay-1);
}
.btn-add:active {
  background-color: var(--bg-gradient-1);
}

.todo-task-items {
  height: 38px;
  width: 405px;
  background-color: var(--bg-gradient-1);
  color: var(--font-color);
  border: var(--glass-border-1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.todo-error {
  border: 1px solid var(--color-alert);
}
.todo-task-delete {
  color: var(--color-alert);
  opacity: 0.1;
  cursor: pointer;
}
.todo-task-delete:hover {
  opacity: 1;
}
.todo-label {
  margin-top: 10px;
  width: 90%;
  margin-bottom: 5px;
}
.task-task-container {
  display: flex;
  height: 400px;
  flex-direction: column;

  align-items: center;
  gap: 5px;
  overflow-x: scroll;
}

.todo-task-finished {
  opacity: 0.7;
  text-decoration: line-through;
}

.todo-checkbox {
  color: var(--font-color) !important;
}
.todo-tab {
  width: 100%;
  height: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--font-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.todo-tab.active {
  background-color: var(--bg-overlay-1);
}
.todo-tab-wrap {
  width: 98%;
  height: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--font-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: var(--glass-border-1);
}
.todo-tab-wrap input {
  height: inherit;
  width: 80%;
  border: none;
  box-sizing: border-box;
}
.todo-tab-wrap button {
  height: inherit;
  width: 20%;
  border: none;
  box-sizing: border-box;
  background-color: var(--color-alert-1);
  color: var(--font-color-2);
}
.btn-add-group {
  background-color: transparent;
  border: none;
  color: var(--font-color);
  cursor: pointer;
}

.todo-label-container {
  height: 35px;
  border-bottom: 1px solid var(--font-color);
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-group-container {
  height: 454px;
  overflow-x: scroll;
}
