.weatherInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 20px;
  position: relative;
  height: 500px;

  width: inherit;
  align-items: center;
  /*  */
  /* 
  border-radius: 5px;
  border: var(--glass-border-1); */
}
.emptyWeatherContainer {
  background-color: var(--bg-overlay-1);
  height: 500px;

  width: inherit;
  border-radius: 10px;
}
.weatherMain {
  width: 410px;
  position: relative;
  border-radius: 10px;
  color: var(--font-color-1);
  margin-top: 30px;
}

.weatherIcons {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 400px;
}
.weatherIconsWrap {
  display: flex;
}

.weatherIcons img {
  width: 84px;
}
.weatherInfoText {
  font-size: 16px;
  width: 150px;
  opacity: 0.9;
}
.weatherInfoOther {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: var(--glass-border-1);
  width: 400px;
  margin-bottom: 8px;
}
/* .weatherItems span {
  color: white;
} */

.weatherItems {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.815);
}

.tempData {
  font-size: 46px;
  display: flex;
  align-items: center;
}

.tempIcon {
  font-size: 32px;
  padding-bottom: 10px;
}

/* choose metric icon styles */
.chooseMetric {
  display: flex;
  height: 30px;
  width: 130px;
  padding-right: 5px;
}

.cImperial {
  border: none;
  background-color: rgba(0, 0, 0, 0.403);
  opacity: 0.7;
  color: var(--font-color-1);
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}
.cMetric {
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.403);
  border: none;
  color: var(--font-color-1);
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.cMetric.active,
.cImperial.active {
  opacity: 1;
  font-weight: bold;
  cursor: text;
}

/* choose metric icon styles */

.weatherChartWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  background: rgb(0 0 0 / 8%);
  backdrop-filter: blur(2px);
  border: 1px solid rgba(255, 255, 255, 0.116);
  border-radius: 10px;
  width: 400px;
  overflow: hidden;
}

.locationName {
  display: flex;
  align-items: center;
  padding-left: 8px;
  font-size: 16px;
  z-index: 10;
}

.locationNameWrap {
  display: flex;
  width: 410px;
  justify-content: space-between;
  position: absolute;
  top: -30px;
  background-color: var(--bg-color-3);
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.searchLocation,
.weatherRefresh {
  background: transparent;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  opacity: 0.3;
  transition: all linear 0.3s;
}

.weatherIconsWrapper {
  background: rgba(0, 0, 0, 0.041);

  border: 1px solid rgba(255, 255, 255, 0.116);
  height: 224px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 15px;
  border-radius: 10px;
}
.searchLocation:hover,
.weatherRefresh:hover {
  opacity: 1;
}

.weather3day {
  display: flex;
  justify-content: space-between;
}
