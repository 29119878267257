.calcContainer {
  width: 330px;
  height: 435px;
  background-color: var(--bg-color-4);
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  border-radius: 10px;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: 5px;
}
.calcScreen {
  height: 100px;
  width: 100%;
  background: var(--bg-overlay-1);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.calcNumFuncWrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(6, 1fr);
  height: 310px;
  gap: 4px;
}
.calcFunc,
.calcNum,
.calcEqual {
  height: 100%;
  border: none;
  color: var(--font-color);
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  font-size: 16px;
  /* border: var(--glass-border-1); */
}

.calcNum {
  background: var(--bg-overlay);
  font-size: 18px;
}
.calcFunc {
  background: var(--bg-overlay-1);
}
.calcEqual {
  background: var(--link-color);
  color: white;
}
.calcNum:hover {
  background: var(--bg-overlay-1);
}
.calcFunc:hover {
  background: var(--bg-overlay);
}
.calcFunc:active {
  background: var(--bg-overlay-1);
}
.calcNum:active {
  background: var(--bg-overlay);
}
.calcEqual:hover {
  background: var(--bg-color-3);
}
.calcEqual:active {
  background: var(--link-color);
}

.calcResScreen {
  height: 100%;
  padding-bottom: 5px;
  margin-right: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
