.forgotPassContainer {
  background-color: var(--bg-input);
  height: 480px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  border-radius: 5px;
}

.forgotPassTitle {
  font-size: 35px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.authForm {
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  justify-content: center;
  align-items: center;
}

.inputEmail {
  display: flex;
  flex-direction: column;
  width: 80%;
  row-gap: 5px;
  position: relative;
}

.forgotInput:focus {
  outline: none !important;
  border-bottom: 2px solid var(--link-color);
}
.goLogIn {
  color: var(--link-color);
}

.forgotInput {
  outline: 0;
  font-size: 16px;
  height: 45px;
  border: none;
  padding-left: 40px;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  background-color: var(--bg-input);
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--font-color);
}
.forgotInput::placeholder {
  color: var(--bg-color-2);
}

.handleSendReset {
  height: 45px;
  /* background-color: rgb(39, 117, 235); */
  background-color: var(--link-color);
  width: 80%;
  border-radius: 5px;
  border: none;
  color: white;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin-top: 5px;
}
/* .handleSendReset:hover {
  background-color: rgb(36, 108, 216);
} */

.reCaptchaInfo {
  font-size: 10px;
  color: var(--font-color-3);
  display: flex;
  justify-content: center;
}
.reCaptchaInfo div {
  width: 80%;
}

.emailIcons {
  position: absolute;
  bottom: 13px;
  left: 10px;
  font-size: 22px !important;

  color: rgb(158, 158, 158);
}

.authLinkInfo {
  color: var(--font-color-3);
}

.authLinkInfo a {
  font-weight: 500;
}

.forgotPassHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.handleSendReset:disabled,
.handleSendReset[disabled]:hover {
  opacity: 0.5;
  cursor: auto;
}

@media (max-width: 768px) {
  .forgotPassContainer {
    height: 100%;
    width: 100%;
    justify-content: center;
    row-gap: 40px;
  }

  .forgotInput {
    font-size: 16px;
    height: 45px !important;
  }
}

.authError {
  color: var(--color-alert);
  width: 80%;
}
.authSuccesMessage {
  color: var(--color-alert-1);
  width: 80%;
}
