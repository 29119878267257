@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,500;1,600&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --font-color: #333;
  --font-color-1: #eee;
  --background-color: #eee;
  --link-color: rgb(39, 117, 235);
  --bg-color-2: rgb(145, 145, 145);
  --font-color-2: #eee;
  --font-color-3: rgb(119, 119, 119);
  --glass-bg-1: rgba(241, 241, 241, 0.13);
  --glass-border-1: 1px solid rgba(109, 109, 109, 0.178);
  --bg-color-3: #204b8b;
  --bg-gradient-1: #e8ecf2;

  --bg-overlay-1: rgba(0, 0, 0, 0.02);
  --bg-color-4: #e4e7ed;
  --bg-input: white;
  --bg-overlay: white;
  --color-alert: rgb(185, 24, 24);
  --color-alert-1: rgb(22, 143, 68);
  --color-purple: rgb(104, 0, 223);
  --glass-bg-2: rgba(241, 241, 241, 0.719);
}

/* 2 */
[data-theme='dark'] {
  --font-color: rgb(214, 214, 214);
  --font-color-1: rgb(207, 207, 207);
  --background-color: #333;
  --link-color: rgba(39, 117, 235, 0.664);
  --bg-color-2: rgb(196, 196, 196);
  --font-color-2: #333;
  --font-color-3: rgb(177, 177, 177);
  --glass-bg-1: rgba(17, 25, 40, 0.329);
  --glass-bg-2: rgba(95, 95, 95, 0.39);
  --glass-border-1: 1px solid rgba(255, 255, 255, 0.125);
  --bg-color-3: #204b8b;
  --bg-gradient-1: #212121;
  --bg-overlay-1: rgba(255, 255, 255, 0.066);
  --bg-color-4: #303030;
  --bg-input: rgba(255, 255, 255, 0.066);
  --bg-overlay: rgb(75, 75, 75);
  --bg-overlay-2: rgb(62, 62, 62);
  --color-alert: rgb(247, 157, 157);
  --color-alert-1: rgb(108, 223, 179);
  --color-purple: rgb(129, 34, 238);
}

/* 3 */
body {
  color: var(--font-color);
  background-blend-mode: normal, color-burn;
  background-blend-mode: screen;
  min-height: 100vh;
  margin: 0 auto;
}

a {
  /* color: var(--link-color); */
  text-decoration: none;
  color: inherit;
}

html {
  scroll-behavior: smooth;
  background: var(--bg-gradient-1);
  height: 100%;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--link-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--bg-overlay);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--font-color-3);
}
