.timerCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  position: relative;
}
.timerCircle {
  height: 150px;
  width: 150px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.timerFinished {
  position: absolute;
  width: 300px;
  background: var(--bg-gradient-1);
  height: 220px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  flex-direction: column;
  gap: 20px;
  -webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.28);
}

.stwBtnAlarm {
  display: flex;
  gap: 20px;
}

.timerTime {
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.timerCircleSvg {
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(-90deg);
  stroke: var(--color-purple);
}

.circleSvg {
  transition: all 0.2s linear;
}

.timerControlBtn {
  display: flex;
  gap: 20px;
}
.timerIncBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timerIncBtn button {
  border: var(--glass-border-1);
  cursor: pointer;
  font-size: 26px;
  color: var(--font-color);
  background: var(--bg-gradient-1);
  border-radius: 5px;
  width: 45px;
  height: 45px;
  opacity: 0.8;
}
.timerIncBtn button:hover {
  opacity: 1;
}
.timerIncBtn button:active {
  opacity: 0.8;
}
#timerMinute,
#timerSecond,
#timerHour {
  font-family: 'Rubik', sans-serif;
  font-size: 26px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timerIncCont {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.timerBtnReset {
  height: 35px;
  width: 100px;
  color: white;
  border: var(--glass-border-1);
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  background: var(--alert-color-1);
}
